import moment from "moment-timezone";
import { decrypt } from "./crypto";

export const formatQRCode = (value: string) => {
    if (!value) return "";
    return `${value.slice(0, 4)}-${value.slice(4)}`;
};

export const formatTimestamp = (timestamp: any) => {
    if (timestamp && moment(timestamp).isValid()) {
        const comps = getTimestampComps(timestamp);
        if (comps && comps.length === 2) {
            const utctime = comps[0],
                localtime = comps[1];
            return `${utctime} (${localtime})`;
        }
    }
    return "";
};

const getTimestampComps = (timestamp: any) => {
    if (timestamp && moment(timestamp).isValid()) {
        const utctime = moment.utc(timestamp).format("DD/MM/YY HH:mm"),
            localtime = moment(timestamp)
                .local()
                .tz(moment.tz.guess())
                .format("z Do HH:mm");
        return [utctime, localtime];
    }
    return null;
};

export const parseSearchParams = (search: string, params: string[]) => {
    let qrcode = "";
    if (!search) return { qrcode };
    const decodedQuery = decrypt(search.substring(1));
    const queryString: any = getURLQueryString(decodedQuery, params);
    return { qrcode: queryString.id };
};

const getURLQueryString = (queryString: string, keys: string[]) => {
    const query = new URLSearchParams(queryString);
    const result: any = {};
    keys.forEach((x) => {
        result[x] = query.get(x) || "";
    });
    return result;
};
