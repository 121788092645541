import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

const key = "7w!z%C*F-JaNdRgU";

export const encrypt = function encrypt(text) {
    const ciphertext = AES.encrypt(text, key).toString();
    return ciphertext;
};

export const decrypt = function decrypt(ciphertext) {
    const bytes = AES.decrypt(ciphertext, key);
    const originalText = bytes.toString(Utf8);
    return originalText;
};
