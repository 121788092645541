import React, { useState } from "react";
import {
    makeStyles,
    TextField,
    Container,
    Grid,
    Typography,
    Button,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import BlockingPreloader from "../common/BlockingPreloader";
import SnackbarMessage from "../common/SnackbarMessage";
import { formatQRCode } from "../../lib/util";
import { saveTrackingConfirmation } from "../../lib/api";

interface IConfirmAndRateDelivery {
    qrCode: string;
    callback: any;
}

const ConfirmAndRateDelivery = (props: IConfirmAndRateDelivery) => {
    const classes = useStyles();
    const { qrCode, callback } = props;
    const [messsage, setMessage] = useState({
        variant: "",
        text: "",
        display: false,
    });
    const [field, setField] = useState({
        firstname: "",
        lastname: "",
        rate: 0,
        comments: "",
    });
    const [loading, setLoading] = useState(false);

    const onChange = (name: string, value: any) => {
        setField((p) => ({ ...p, [name]: value }));
    };

    const onSubmit = async () => {
        setLoading(true);
        const payload = { qrcode: qrCode, ...field };
        let submitted = false;
        try {
            const res = await saveTrackingConfirmation(payload);
            if (!res?.data?.qrcode) {
                const errorMessage =
                    res?.data?.error ||
                    "Failed to save confirmation, please try it again";
                throw new Error(errorMessage);
            }
            submitted = true;
        } catch (error: any) {
            setMessage({
                variant: "error",
                text: error.message,
                display: true,
            });
        } finally {
            setLoading(false);
        }
        if (submitted) await callback();
    };

    return (
        <>
            <Container maxWidth="xs">
                <Grid container spacing={4} className={classes.root}>
                    <Grid item xs={12}>
                        <div>
                            <Typography>
                                Please confirm recepit of package:{" "}
                            </Typography>
                            <Typography variant="h4">
                                {formatQRCode(qrCode)}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={field.firstname}
                            variant="outlined"
                            fullWidth
                            label="Firstname"
                            name="firstname"
                            onChange={({ target }) => {
                                onChange("firstname", target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={field.lastname}
                            variant="outlined"
                            fullWidth
                            label="Lastname"
                            name="lastname"
                            onChange={({ target }) => {
                                onChange("lastname", target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="legend">Rate</Typography>
                        <Rating
                            name="field"
                            value={field.rate}
                            onChange={(event: any, newValue: any) => {
                                onChange("rate", newValue);
                            }}
                            size="large"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={field.comments}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            label="Comments"
                            name="comments"
                            onChange={({ target }) => {
                                onChange("comments", target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGrid}>
                        <Button
                            onClick={onSubmit}
                            color="secondary"
                            disabled={!field.firstname || !field.lastname}
                            variant="contained"
                        >
                            confirm
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <BlockingPreloader open={loading} />
            <SnackbarMessage
                open={messsage.display}
                message={messsage.text}
                variant={messsage.variant}
                onClose={() => {
                    setMessage({ display: false, text: "", variant: "error" });
                }}
            />
        </>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: theme.spacing(1),
            diplay: "flex",
            alignItems: "center",
        },
        buttonGrid: { display: "flex", justifyContent: "center" },
    };
});

export default ConfirmAndRateDelivery;
