import React, { FunctionComponent } from "react";
import {
    makeStyles,
    CircularProgress,
    LinearProgress,
    Backdrop,
    Box,
    Typography,
} from "@material-ui/core";

const MuiBox = Box as any;

interface IBlockingPreloader {
    open: boolean;
    mode?: string;
    value?: number;
    label?: string;
}

const BlockingPreloader: FunctionComponent<IBlockingPreloader> = ({
    open,
    mode = "circular",
    value = 0,
    label,
}) => {
    const classes = useStyles();
    let progress;
    if (mode === "line") {
        progress = (
            <div className={classes.linearProgress_upper}>
                <MuiBox
                    display="flex"
                    alignItems="center"
                    className={classes.linearProgress_box}
                >
                    <LinearProgress />
                </MuiBox>
            </div>
        );
    } else if (mode === "linebufferwithlabel") {
        const diff = Math.random() * 10;
        const buffer = value + diff;
        progress = (
            <div className={classes.linearProgressWithLabel_upper}>
                <MuiBox
                    display="flex"
                    alignItems="center"
                    className={classes.linearProgress_box}
                >
                    <MuiBox width="100%" mr={1}>
                        <LinearProgress
                            classes={{
                                root: classes.linearProgressWithLabel,
                                buffer: classes.linearProgressWithLabel,
                                dashed: classes.linearProgressWithLabelDashed,
                            }}
                            variant="buffer"
                            value={value}
                            valueBuffer={buffer}
                        />
                    </MuiBox>
                    <MuiBox minWidth={35}>
                        <Typography className={classes.label} variant="body2">
                            {label}
                        </Typography>
                    </MuiBox>
                </MuiBox>
            </div>
        );
    } else {
        progress = <CircularProgress variant="indeterminate" />;
    }
    return (
        <Backdrop open={open} className={classes.backdrop}>
            {progress}
        </Backdrop>
    );
};

const useStyles = makeStyles((theme) => {
    return {
        linearProgress_upper: {
            marginLeft: "auto",
            marginRight: "auto",
            width: 200,
        },
        linearProgressWithLabel_upper: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "60%",
        },
        linearProgress_box: { height: "100vh" },
        circularProgress_upper: { height: "100vh", alignItems: "center" },
        label: { color: "#DCDDDF" },
        linearProgressWithLabel: { height: 10 },
        linearProgressWithLabelDashed: {
            backgroundSize: "20px 1px",
            height: 10,
        },
        backdrop: {
            zIndex: theme.zIndex.modal || 0 + 1,
            color: "#fff",
        },
    };
});

export default BlockingPreloader;
